import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import styles from './styles.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import polyline from '@mapbox/polyline'; // eslint-disable-line import/no-webpack-loader-syntax
import WarehouseIcon from '@mui/icons-material/Warehouse';
import wrlog from '../../Helper/functions/wrlog';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWZyZXNoZWQiLCJhIjoiY2w0Y214aDB2MDAzZjNicGE5b2RhMXl1cSJ9.Hj4CU2_lKV7E52qb_dTQ7A';

interface MapProps {
    flyTo?: any;
    ref?: any;
    draggableMarker?: object;
    highlightedRouteStopIds?: any;
    onDragged?: any;
    style?: object;
}

const draggableGeojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [0, 0]
            }
        }
    ]
};


const Map = React.forwardRef((props: MapProps, ref) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(13.76667);
    const [lat, setLat] = useState(47.633331);
    const [zoom, setZoom] = useState(7);
    const [loaded, setLoaded] = useState(false);

    const {
        style
    } = props;

    useImperativeHandle(ref, () => ({
        flyTo(location: any) {
            setTimeout(
                () => map?.current?.flyTo({
                    center: [
                        location[0],
                        location[1]
                    ],
                    zoom: 15,
                    speed: 3,
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                }), 1000)
        },
        setMarker(marker: any) {
            const markerElement = document.createElement('div');
            markerElement.classList.add(styles.marker);
            markerElement.classList.add(styles.dropoff);

            setTimeout(
                () => new mapboxgl.Marker(markerElement)
                    .setLngLat([marker.longitude, marker.latitude])
                    .addTo(map.current), 1000)
        },
        loaded() {
            return loaded;
        }
    }))

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'https://api.maptiler.com/maps/ab5efeec-0edb-4a21-8698-2aa7d44508cd/style.json?key=as655Sc4TpwS024zFCfw',
            center: [lng, lat],
            zoom: zoom
        });

        map.current.on('load', () => {
            setLoaded(true);
        });

    }, [map.current]);

    return <div>
        <div ref={mapContainer} style={style} className={styles.mapContainer} />
    </div>

})

export default Map;