// import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, Link, BrowserRouter as Router } from 'react-router-dom';
import { changeLanguageHandler } from './Helper/multilingual';
import Home from './Pages/Home';
import DeliveryDetail from './Pages/DeliveryDetail';
// import WsTest from './Pages/Testing/ws';

const App = () => {

    changeLanguageHandler();

    return (
        <Router>
            <Routes>
                <Route path="/:trackingCode" element={<DeliveryDetail />} />
                <Route path="/:trackingCode/:email" element={<DeliveryDetail />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </Router>
    )

};

export default App;