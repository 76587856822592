import React, { useEffect, useRef, useState } from 'react';

import Logo from '../../Assets/Logo.png';

import styles from './styles.module.scss';

import { Card, Grid, Skeleton, TextField } from '@mui/material';
import Button from '../../Components/Button';
import wrlog from '../../Helper/functions/wrlog';
import TrackerUnit from '../../Services/Http/Tracker';
import { translate } from '../../Helper/multilingual';
import Footer from '../../Components/Footer';
import { useNavigate } from 'react-router-dom';

/**
 * 
 * @returns Login Page
 */
const Home = () => {

    const navigate = useNavigate();

    const [trackingCode, setTrackingCode] = useState('');
    const [notFound, setNotFound] = useState(false);

    const buttonRef = useRef() as { current: any };

    const getDelivery = () => {
        TrackerUnit.verifyTrackingCode(trackingCode).then((res: any) => {
            navigate(`/${trackingCode}`);
            buttonRef.current.changeState('success')
        }).catch((err: any) => {
            buttonRef.current.changeState('error')
            setNotFound(true);
        })
    }

    return (
        <>
            <Grid container padding={0} style={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
                <Grid item md={12} padding={0} style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '95%' }}>
                    <img src={Logo} style={{ width: 100, marginBottom: 50 }} />
                    <Card
                        className='loginCard'>
                        <h4 style={{ marginTop: 10, marginBottom: 20 }}>Lieferung suchen</h4>
                        <TextField
                            id="standard-basic"
                            label={translate('Tracking-Code')}
                            type="text"
                            value={trackingCode}
                            onChange={e => setTrackingCode(e.target.value)}
                            className={`dark`}
                        />
                        {notFound
                            && <p style={{ paddingTop: 20, color: 'red' }}>Der Tracking Code wurde nicht gefunden.</p>
                        }
                        <Button
                            type='primary'
                            ref={buttonRef}
                            label={translate('Suchen')}
                            onClick={getDelivery}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default Home;