import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../../Assets/Logo.png';

import styles from './styles.module.scss';

interface SidebarProps {
    children: any;
}

/**
 * 
 * @returns Login Page
 */
const Sidebar = (props: SidebarProps) => {

    return (
        <div className={styles.container}>
            {props.children}
        </div>
    );
}

export default Sidebar;