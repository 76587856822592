import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Logo from '../../Assets/Logo.png';

import styles from './styles.module.scss';

const Footer = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <a href='https://afreshed.at/impressum' target={'_blank'} style={{margin: '0px 10px'}}>Impressum</a>
                <a href='https://afreshed.at/datenschutzerklaerung' target={'_blank'} style={{margin: '0px 10px'}}>Datenschutz</a>
                <a href='https://afreshed.at/agb' target={'_blank'} style={{margin: '0px 10px'}}>AGB</a>
            </div>
        </div>
    )
};

export default Footer