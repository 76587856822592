import Unit from './index';

export default class TrackerUnit extends Unit {

    static verifyTrackingCode = async (trackingCode: string) => {
        return await Unit.get(`/tracker/verify/${trackingCode}`);
    }

    static getSimple = async (trackingCode: string) => {
        return await Unit.get(`/tracker/${trackingCode}`);
    }

    static getDetails = async (trackingCode: string, base64Email: string) => {
        return await Unit.get(`/tracker/${trackingCode}/${base64Email}`);
    }

    static updateNote = async (trackingCode: string, base64Email: string, note: string) => {
        return await Unit.put(`/tracker/${trackingCode}/${base64Email}`, { note });
    }

}



