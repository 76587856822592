import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../Helper/multilingual';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../Assets/Logo.png';
import { Buffer } from "buffer";

import styles from './styles.module.scss';
import buttonStyles from '../../Components/Button/style.module.scss';

import { Card, Grid, Skeleton, TextField } from '@mui/material';
import Sidebar from './Sidebar';
import Map from '../../Components/Map';
import Button from '../../Components/Button';
import TrackerUnit from '../../Services/Http/Tracker';
import wrlog from '../../Helper/functions/wrlog';
import { API_ENDPOINT } from '../../config';

/**
 * 
 * @returns Login Page
 */
const DeliveryDetail = () => {

    const [email, setEmail] = useState('' as string);
    const [error, setError] = useState(false as Boolean);
    const [data, setData] = useState({} as any);
    const [editNote, setEditNote] = useState(false);
    const [note, setNote] = useState('');

    const params = useParams() as { trackingCode: string, email: string };
    const mapRef = useRef() as { current: any };
    const buttonRef = useRef() as { current: any };
    const navigate = useNavigate();

    useEffect(() => {
        if (params.trackingCode && !params.email && !data.history) {
            loadSimple();
        } else if (params.trackingCode && params.email && !data.history) {
            const _email = Buffer.from(params.email, 'base64').toString();
            loadDetails(_email);
        }
    }, [])

    const loadSimple = () => {
        TrackerUnit.getSimple(params?.trackingCode).then((res: any) => {
            setData(res)
        }).catch((err: any) => {
            navigate('/')
        })
    }

    const loadDetails = (_email: string) => {
        const base64 = Buffer.from(_email).toString('base64');
        TrackerUnit.getDetails(params?.trackingCode, base64).then((res: any) => {
            buttonRef?.current?.changeState('success');
            mapRef?.current?.flyTo([res.location.longitude, res.location.latitude]);
            mapRef?.current?.setMarker(res.location);

            navigate(`/${params.trackingCode}/${base64}`, {
                replace: true
            });

            setData(res);

            if (res?.address?.note) {
                setNote(res?.address?.note)
            }
        }).catch((err: any) => {
            buttonRef?.current?.changeState('error')
            setError(true);
            wrlog(err);
            navigate('/');
        })
    }

    const save = async () => {
        setEditNote(false);
        await TrackerUnit.updateNote(params.trackingCode, params?.email, note);
    }

    if (!data?.history) {
        return <div className='loadingWrapper'>
            <div className='loadingContainer'>
                <img className="logo" src={Logo} />
                <div className={'loader-ellipsis'}><div></div><div></div><div></div><div></div></div>
            </div>
            <div>Details werden geladen</div>
        </div>
    }

    return (
        <>
            <Sidebar>
                <img src={Logo} className="logo" />
                <hr style={{ width: '100%' }} />
                <h3 style={{ marginBottom: 10 }}>{translate('Auslieferung')}</h3>
                <span className="mobileSmall">#{params.trackingCode}</span>
                <hr style={{ width: '100%' }} />
                {
                    data?.history?.map((history: any, key: number) => {
                        return <div
                            key={key}
                            className={`${styles.historyContainer} ${styles.historyContainerMobile}`}
                        >
                            <div className={`${styles.historyContainer}`}>
                                <div className={`${styles.circle} ${styles[history.status]}`}></div>
                                <div className={`${styles.headline}`}>
                                    <strong>{translate(history.title)}</strong><br />
                                    <small>{history.dateTime}</small>
                                </div>
                            </div>
                            {
                                key + 1 < history.length
                                && <div className={`${styles.stroke} ${styles[history.status]}`}></div>
                            }
                            {
                                history.button
                                && <a
                                    href={history.button.link}
                                    target={'_blank'}
                                    className={`${buttonStyles.button} ${buttonStyles.tertiary} sideButton`}
                                >
                                    {history.button.label}
                                </a>
                            }
                        </div>
                    })
                }
                <hr style={{ width: '100%' }} />
                <h3 style={{ marginBottom: 10 }}>{translate('Zustellinformationen')}</h3>
                {
                    data.parcels
                        ? <>
                            <strong>Adresse</strong><br />
                            {data?.address?.name}<br />
                            {data?.address?.address} {data?.address?.streetNumber}<br />
                            {data?.address?.postcode} {data?.address?.city}<br />
                            <br />
                            <Card style={{ position: 'relative' }}>
                                {/* http://localhost:3001/0BoFS4r4OQm4oir7cD5CbmSU2Y5VMA/bWF4aXdvMjdAZ21haWwuY29t */}
                                {editNote
                                    ? <Button
                                        type='tertiary'
                                        style={{ position: 'absolute', top: 12, right: 10 }}
                                        label={translate('speichern')}
                                        onClick={() => save()}
                                        className={"green"}
                                    />
                                    : <Button
                                        type='tertiary'
                                        style={{ position: 'absolute', top: 12, right: 10 }}
                                        label={translate('bearbeiten')}
                                        onClick={() => setEditNote(true)}
                                    />
                                }
                                <strong>Notiz</strong><br /><br />
                                {
                                    editNote
                                        ? <TextField
                                            id="standard-basic"
                                            label={translate('Notiz')}
                                            type="text"
                                            multiline
                                            className='dark'
                                            value={note}
                                            onChange={e => setNote(e.target.value)}
                                        />
                                        : (
                                            note ? note : translate('Keine Notiz')
                                        )
                                }
                            </Card>


                            <hr />
                            <strong>Pakete</strong><br />
                            {
                                data?.parcels?.map((parcel: any, key: number) => {

                                    return <div key={key}>
                                        <strong>1x {parcel?.title || parcel?.sku}</strong><br />
                                        <small>{parcel?.companyTitle}</small>
                                        <div className={styles.proofOfDeliveryContainer}>
                                            {
                                                parcel?.proof
                                                && Object.keys(parcel?.proof).map((proofKey: any, key: number) => {
                                                    if (parcel?.proof[proofKey].image) {
                                                        return <img
                                                            key={key}
                                                            src={`${API_ENDPOINT}/bucket/${parcel?.proof[proofKey].image}`}
                                                        />
                                                    } else if (parcel?.proof[proofKey].images) {
                                                        return parcel?.proof[proofKey].images.map((image: string, key: number) => {
                                                            return <img
                                                                key={key}
                                                                src={`${API_ENDPOINT}/bucket/${image}`}
                                                            />
                                                        })
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }

                        </>
                        : <>
                            <TextField
                                id="standard-basic"
                                label={translate('E-Mail eingeben')}
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {error
                                && <p style={{ paddingTop: 20, color: 'red' }}>Mit dieser E-Mail-Adresse wurden leider keine Informationen gefunden.</p>
                            }
                            <Button
                                type='primary'
                                ref={buttonRef}
                                label={translate('Prüfen')}
                                onClick={() => loadDetails(email)}
                            />
                        </>
                }
                <div style={{ width: '100%', height: 40 }}></div>
            </Sidebar>
            <div className='mapContainer'>
                {data.notification
                    && <div className={styles.notification}>
                        <div className={styles.icon}>
                            <div className={`${styles.circle} ${styles[data.notification.status]}`}></div>
                        </div>
                        <div className={styles.content}>
                            <h3>{data.notification.headline}</h3>
                            <span>{data.notification.body}</span>
                        </div>
                    </div>
                }
                <Map
                    ref={mapRef}
                />
            </div>

        </>
    );
}

export default DeliveryDetail;